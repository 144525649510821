import * as Msdyn365 from '@msdyn365-commerce/core';
// import { ApplicationInsights } from '@microsoft/applicationinsights-web';
// import { APPLICATIONINSIGHTS_INSTRUMENTATIONKEY } from '../configuration/telemetry';

// const appInsights = new ApplicationInsights({
//     config: {
//         instrumentationKey: APPLICATIONINSIGHTS_INSTRUMENTATIONKEY
//     }
// });

export async function trackExecutionTime<T>(
    asyncFunc: () => Promise<T>,
    funcName: string,
    ctx: Msdyn365.IActionContext
): Promise<{ result: T }> {
    const startExecution = performance.now();
    const result = await asyncFunc();
    const endExecution = performance.now();
    const engagementTime = endExecution - startExecution;
    ctx.telemetry.information(`${funcName} - WEB`, engagementTime);
    ctx.telemetry.trackMetric(`${funcName} - WEB`, engagementTime);
    // appInsights.trackMetric({ name: `${funcName} - WEB`, average: engagementTime });
    return { result };
}

export async function trackedAction<T>(
    action: (input: any, ctx: Msdyn365.IActionContext) => Promise<any>,
    input: unknown,
    ctx: Msdyn365.IActionContext,
    actionName: string
): Promise<any> {
    const { result } = await trackExecutionTime(() => action(input, ctx), actionName, ctx);
    return result;
}
