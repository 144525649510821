import { ICoreContext } from '@msdyn365-commerce/core';

/**
 * Formats a number.
 * @param context - Core context.
 * @param date - Number to display.
 * @returns String value.
 */
// Example usage: formatNumber(this.props.context, new Date(CreatedDate)

export const formatNumber = (context: ICoreContext, number: number | undefined) => {
    if (number === undefined) {
        return '';
    }

    /**
     * don't do this, since it adds separators in unwanted places
    const numberOptions: INumberFormatOptions = { style: 'decimal' };
    const formattedNumber = context.cultureFormatter.formatNumber(number, numberOptions);
    //const formattedNumber = number.toString().replace(/\.\d+$/, '');
    */
    // Simply drop decimal points and convert to string
    // return Math.trunc(number).toString();
    return number.toString();
};
